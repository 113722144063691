import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import _ from "lodash";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import { DataGrid, GridToolbar, gridClasses } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import { setLoading, setSnackbarMsg } from "../../../store/reducers/misc";
import { checkingTicket } from "../../../lib/request";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import StadiumIcon from "@mui/icons-material/Stadium";
import PercentIcon from "@mui/icons-material/Percent";

export default function Tab4(props) {
  const { EticketPackages: packages } = props.event;
  const [tickets, setTickets] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const dispatch = useDispatch();
  const [paidTickets, setPaidTickets] = useState(0);
  const [checkedIn, setCheckedIn] = useState(0);
  const [checkInPercentage, setCheckInPercentage] = useState(0);

  useEffect(() => {
    const tkt = [];
    packages.forEach((p) => {
      p.Eticketqr.forEach((peQr) => {
        const payment = peQr.Eticket.EticketPayments;
        const profile = payment.Profile;
        const ticketData = {
          id: peQr.id,
          packageName: p.title,
          orderId: payment.orderId,
          ticketId: peQr.ticketId,
          seatNo: peQr.seatNo,
          qr: peQr.qrHash,
          used: peQr.isUsed,
          usedAt: peQr.usedAt,
          payment,
          profileName: profile.name,
          email: profile?.Account?.email || "",
          payDate: payment.payDate,
          checkInStatus: peQr.isUsed,
          checkInStatusValue: peQr.isUsed ? "Done" : payment.payDate ? (payment.status === "00" ? "Check In" : "Failed") : "Unpaid",
        };
        const index = _.findIndex(tkt, { id: peQr.id });
        if (index === -1) {
          tkt.push(ticketData);
        }
      });
    });
    setTickets(tkt);
    console.log(tkt);
    let ticketCounter = 0;
    let checkedInCounter = 0;
    tkt.forEach((t) => {
      if (t.payment.status === "00") {
        ticketCounter++;
        if (t.used) {
          checkedInCounter++;
        }
      }
    });
    setPaidTickets(ticketCounter);
    setCheckedIn(checkedInCounter);

    const percentage = ticketCounter > 0 ? (checkedInCounter / ticketCounter) * 100 : 0;
    setCheckInPercentage(parseFloat(percentage.toFixed(1)));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packages]);

  const handleCheckIn = (code) => {
    dispatch(setLoading(true));
    const data = { code: code };

    checkingTicket(data)
      .then((response) => {
        const { ticket } = response.data.data;

        // Update the state with the new ticket data
        setTickets((prevTickets) => {
          const updatedTickets = prevTickets.map((t) =>
            t.qr === code ? { ...t, used: ticket.isUsed, usedAt: ticket.usedAt, checkInStatus: ticket.isUsed, checkInStatusValue: ticket.isUsed ? "Done" : "Check In" } : t
          );

          const newCheckedInCount = updatedTickets.filter((t) => t.used).length;
          const percentage = paidTickets > 0 ? (newCheckedInCount / paidTickets) * 100 : 0;

          setCheckedIn(newCheckedInCount);
          setCheckInPercentage(parseFloat(percentage.toFixed(1)));

          return updatedTickets;
        });

        dispatch(setSnackbarMsg("User Success Checking"));
      })
      .catch((err) => {
        if (err.response) {
          dispatch(setSnackbarMsg(err.response.data.message));
        }
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  const columns = [
    { field: "id", headerName: "#", minWidth: 30 },
    { field: "qr", headerName: "QR", align: "center", minWidth: 150, renderCell: (params) => <QRCode size={80} value={params.value} /> },
    { field: "packageName", headerName: "Package name", align: "center", minWidth: 150 },
    { field: "ticketId", headerName: "Ticket ID", minWidth: 250 },
    { field: "orderId", headerName: "Order ID", minWidth: 250 },
    { field: "seatNo", headerName: "Seat No", minWidth: 250 },
    { field: "profileName", headerName: "Buyer name", minWidth: 150 },
    { field: "email", headerName: "Buyer email", minWidth: 300 },
    { field: "payDate", headerName: "Date buy", minWidth: 250, renderCell: (params) => (dayjs(params.value).isValid() ? dayjs(params.value).format("DD MMM YYYY hh:mm A") : "") },
    {
      field: "checkInStatus",
      headerName: "Check In Status",
      minWidth: 200,
      align: "center",
      renderCell: (params) =>
        params.row.used ? (
          <Chip label="Done" color="success" />
        ) : params.row.payDate ? (
          params.row.payment.status === "00" ? (
            <Button fullWidth variant={"contained"} color={"warning"} onClick={() => handleCheckIn(params.row.qr)}>
              Check In
            </Button>
          ) : (
            <Chip label="Failed" color="error" />
          )
        ) : (
          <Chip label="Unpaid" />
        ),
    },
    { field: "checkInStatusValue", headerName: "Check In Status (Hidden)", hide: true },
  ];

  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <Grid container justifyContent="center" sx={{ px: { xs: 2, md: 0 } }}>
        <Grid item xs={12} md={4} sx={{ mb: 2, mt: 2 }}>
          <Card sx={{ borderTop: "4px solid #f15b2d" }} variant="outlined">
            <CardContent>
              <Typography component="h1" variant="h6">
                Attendees checked in
              </Typography>
            </CardContent>
            <CardContent style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <StadiumIcon style={{ fontSize: "1.2rem", marginRight: "1rem" }} />
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <Typography style={{ fontSize: "1.5rem", color: "#43a047" }}>
                    {checkedIn}/{paidTickets}
                  </Typography>
                </div>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <PercentIcon style={{ fontSize: "1.2rem", marginRight: "1rem" }} />
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <Typography style={{ fontSize: "1.5rem", color: "#43a047" }}>{checkInPercentage}</Typography>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <DataGrid
        getRowHeight={() => "auto"}
        sx={{
          [`& .${gridClasses.cell}`]: {
            py: 1,
          },
          [`.MuiDataGrid-toolbarContainer`]: {
            py: 1,
            px: 3,
            borderBottom: "1px solid #e0e0e0",
          },
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: "id", sort: "asc" }],
          },
        }}
        rows={tickets}
        autoHeight
        disableDensitySelector
        columns={columns}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 25]}
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
      />
    </Box>
  );
}
